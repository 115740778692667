<template>
  <v-container fluid>
    <v-list-item three-line>
      <v-list-item-content>
        <QuickTranslation
          translation-key="equipment.label"
          tag="h1"
        />
      </v-list-item-content>
    </v-list-item>
    <v-data-table
      :headers="headers"
      :search="search"
      :items="equipments"
      :options.sync="options"
      :server-items-length="totalFromServer"
      v-model="selectedItems"
      :show-select="showCheckBox"
      class=" agTable"
      :loading="loading"
      checkbox-color="primary"
      :no-results-text="$t('common.ui.not_search_found')"
      :no-data-text="$t('common.ui.not_data_found')"
      :loading-text="$t('common.notification.loadData')"
      :footer-props="{
        ...rowsPerPageItemsOptions,
        itemsPerPageText: $t('common.table.itemsPerPageText'),
        pageText: '{0}-{1} ' + $t('common.table.pageText') + ' {2}',
      }"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-row class="d-flex justify-space-between align-baseline">
            <v-col class="pl-0" lg="4" md="4" sm="12" xl="4">
              <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replaceSearch" v-on:keyup.ctrl.86="replaceSearch"
                dense
                filled
                rounded
                color="primary"
                v-model="search"
                @keypress.enter="searchForText"
                @click:clear="searchAll"
                @click:append="searchForText"
                append-icon="mdi-magnify"
                :label="$t('common.titles.filter')"
                :clearable="true"
                single-line
                hide-details
              />
            </v-col>
            <v-col lg="4" md="4" sm="12" xl="4">
              <v-btn
                small
                tile
                :elevation="0"
                color="gray"
                style="border-radius: 1.5em;"
                @click="checkBoxShow"
                class="mr-1"
                :disabled="!(equipments && equipments.length > 0)"
              >
                <span v-if="!showCheckBox">
                  <v-icon small color="neuter" left
                    >mdi-checkbox-multiple-marked-outline</v-icon
                  >
                  <span class="neuter--text">{{
                    $t("common.buttons.checksBoxEnable")
                  }}</span>
                </span>
                <span v-else>
                  <v-icon small color="neuter" left
                    >mdi-checkbox-multiple-marked</v-icon
                  >
                  <span>{{ $t("common.buttons.checksBoxDisable") }}</span>
                </span>
              </v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <v-col
              lg="4"
              md="4"
              sm="8"
              xl="4"
              class="text-end d-flex justify-end"
            >
              <ButtonGroupComponent :buttons="getButtons()" />
              <v-dialog persistent v-model="dialog" max-width="500px">
                <v-card :light="true" color="white">
                  <v-card-title class="dialog__header">
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text class="px-6">
                    <v-container>
                      <v-form ref="form" v-model="valid">
                        <v-row>
                          <v-col cols="12" class="d-flex justify-end">
                            <v-switch
                              v-model="editedItem.active"
                              color="primary"
                            >
                              <template v-slot:prepend>
                                <v-label ref="label"
                                  >{{ $t("equipment.fields.active") }}
                                </v-label>
                              </template>
                            </v-switch>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field outlined dense onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replaceEquipmentName" v-on:keyup.ctrl.86="replaceEquipmentName"
                              color="primary"
                              v-model="editedItem.equipment_name"
                              required
                              class="required"
                              :rules="requireAndMaxRules(max)"
                              :counter="max"
                              maxlength="max"
                            >
                              <template v-slot:label>
                                  {{$t('equipment.fields.name')}} <strong class="red--text">*</strong>
                              </template>
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field outlined dense onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replaceCode" v-on:keyup.ctrl.86="replaceCode"
                              color="primary"
                              v-model="editedItem.code"
                              required
                              class="required"
                              :rules="requireRules"
                            >
                              <template v-slot:label>
                                  {{$t('equipment.fields.code')}} <strong class="red--text">*</strong>
                              </template>
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-textarea outlined dense onkeypress="return (event.charCode != 34)"
                            v-on:keyup.86="replaceDescriptionText" v-on:keyup.ctrl.86="replaceDescriptionText"
                              v-model="editedItem.description_text"
                              color="primary"
                              :rules="
                                lengthRules(250).concat(limitEnterKeyPress)
                              "
                              :auto-grow="true"
                              :clearable="true"
                              :label="$t('equipment.fields.description')"
                              :no-resize="true"
                              :row-height="40"
                              :rows="3"
                              :counter="250"
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <upload-evidence :nomenclator="editedItem" :loading="loadingEvidences" />
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions class="pa-6">
                    <v-spacer></v-spacer>
                    <v-btn small text color="neuter" @click="close">
                      {{ $t("common.buttons.cancel") }}
                    </v-btn>
                    <v-btn
                      small elevation="0" color="primary" class="t-bw-primary--text"
                      :disabled="!valid"
                      @click="save"
                    >
                      {{ $t("common.buttons.save") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              color="neuter"
              small
              class="mr-2"
              @click="showItem(item)"
              v-if="check([{ domain: 'Equipment', permisions: ['Update'] }])"
            >
              mdi-eye-outline
            </v-icon>
          </template>
          <span>
            {{ $t("common.buttons.show") }}
            <span style="font-size: 12px; color: #AAAAAA">
              {{ $t("equipment.label") }}
            </span>
          </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              v-if="check([{ domain: 'Equipment', permisions: ['Update'] }])"
              color="neuter"
              class="mr-2"
              small
              @click="editItem(item)"
            >
              mdi-square-edit-outline
            </v-icon>
          </template>
          <span>
            {{ $t("common.buttons.edit") }}
            <span style="font-size: 12px; color: #AAAAAA">
              {{ $t("equipment.label") }}
            </span>
          </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              v-if="check([{ domain: 'Equipment', permisions: ['Delete'] }])"
              color="neuter"
              small
              @click="deleteItemDialog(item)"
            >
              mdi-trash-can-outline
            </v-icon>
          </template>
          <span>
            {{ $t("common.buttons.delete") }}
            <span style="font-size: 12px; color: #AAAAAA">
              {{ $t("equipment.label") }}
            </span>
          </span>
        </v-tooltip>
      </template>
      <template v-slot:item.active="{ item }">
        <v-icon
          small
          class="mr-4"
          color="success"
          v-if="item.active"
        >
          mdi-checkbox-marked-circle-outline
        </v-icon>
        <v-icon small class="mr-4" color="disabled" v-else>
          mdi-checkbox-marked-circle-outline
        </v-icon>
      </template>
      <template v-slot:item.description_text="{ item }">
        <div
          class="mr-4"
          v-if="item.description_text.toUpperCase() === 'NULL'"
        >
          -
        </div>
        <div class="mr-4" v-else style="width: 200px; overflow-wrap: break-word;">
          <ReadMore
            :classes="'mb-0'"
            :font-size="14"
            :max-chars="50"
            :text="item.description_text"
          />
        </div>
      </template>
    </v-data-table>
    <v-row justify="center">
      <v-form ref="form" v-model="valid">
        <v-dialog
          v-if="editedItem"
          v-model="showItemDetailsDialog"
          persistent
          scrollable
          width="600px"
        >
          <v-card>
            <v-card-title style="display: flex; justify-content: space-between; align-content: center; align-items: flex-start;" class="px-6">
              {{ editedItem.equipment_name }}
            </v-card-title>
            <v-card-subtitle class="px-6">
              <div style="display: flex">
                <p class="mr-2">{{ $t("equipment.fields.code") }}:</p>
                {{ editedItem.code }}
              </div>
            </v-card-subtitle>
            <v-divider light></v-divider>
            <v-card-text class="pa-6">
              <div class="row mt-2">
                <div class="col-12">
                  <div class="primary"
                    style=" border-radius: 5px; padding:7px; text-align: start;"
                  >
                    <h3 style="padding-left: 5px" class="t-bw-primary--text">
                      {{ $t("equipment.fields.description") }}
                    </h3>
                  </div>
                  <div
                    class="mt-2"
                    style="background-color: #f8f8f8; border-radius: 5px; padding:7px; text-align: start;"
                  >
                    {{ editedItem.description_text || '-' }}
                  </div>
                </div>
              </div>
            </v-card-text>
            <v-divider light></v-divider>
            <v-card-actions class="pa-6">
              <v-btn
                icon
                fab
                v-if="editedItem.evidences && editedItem.evidences.length > 0"
                text
                :loading="loadingDownloadEvidences"
                :disabled="loadingDownloadEvidences"
                small
                @click="downloadEvidence(editedItem)"
              >
                <v-icon color="neuter">
                  mdi-file-download-outline
                </v-icon>
              </v-btn>

              <v-spacer></v-spacer>
              <v-btn small class="t-bw-primary--text"
                     elevation="0"
                     color="primary" @click="close">
                {{ $t("common.buttons.close") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="showDialog" persistent width="600px" scrollable>
          <v-card>
            <v-card-title class="headline secondary t-bw-secondary--text">{{ title }}</v-card-title>
            <v-card-text class="pa-6">{{ dialogText }}</v-card-text>
            <v-card-actions class="pa-6">
              <v-spacer></v-spacer>
              <v-btn small text color="neuter" @click="dialogClose">
                {{ $t("common.buttons.cancel") }}
              </v-btn>
              <v-btn small elevation="0" color="error" class="t-bw-error--text" @click="deleteItem">
                {{ $t("common.buttons.confirm") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </v-row>
  </v-container>
</template>

<script>
import i18n from "@/plugins/i18n";
import { mapActions, mapGetters } from "vuex";
import LanguajeService from "../../../services/LanguajeService";
import PermisionService from "../../../services/PermisionService";
import MoreOptions from "../../../components/MoreOptions";
import EquipmentService from "../../../services/EquipmentService";
import UploadEvidence from "../../../components/UploadEvidence";
import _ from "lodash";
import ReadMore from "@/components/ReadMoreComponent.vue";
import ButtonGroupComponent from "@/components/common/ButtonGroup/ButtonGroupComponent.vue";

const PROFILE = JSON.parse(localStorage.getItem('profile'));

export default {
  components: {ReadMore, UploadEvidence, MoreOptions, ButtonGroupComponent },
  data: () => ({
    profile: null,
    dialog: false,
    editedIndex: -1,
    search: "",
    selectedItem: null,
    icon: null,
    rowsPerPageItemsOptions: {
      itemsPerPageOptions: [10, 20, 50, 100, 200],
    },
    editedItem: {
      equipment_name: "",
      code: "",
      active: true,
      description_text: "",
      evidences: [],
    },
    defaultItem: {
      active: true,
    },
    showDialog: false,
    title: "Información",
    dialogText: "Añada un texto a este dialogo",
    valid: false,
    max: 60,
    deletingItem: {},
    selectedItems: [],
    showCheckBox: false,
    isEnabledCheckBox: false,
    showItemDetailsDialog: false,
    previousDocuments: [],
    documentSelected: null,
    loadingEvidences: false,
    loadingDownloadEvidences: false,
    loadingExport: false,
    options: {},
    defaultFilters: {
      companyId: PROFILE.company_id,
      search: "",
      pageableDTO: {
        page: 0,
        size: 10,
        sortBy: 'id',
        direction: 'ASC',
      }
    },
  }),

  computed: {
    optionsTable: {
      get() {
        return this.$store.getters['ui/GET_OPTIONS_TABLE']
      },
      set(val) {
        this.$store.dispatch('ui/SET_OPTIONS_TABLE', val)
      },
    },
    notification: {
      get() {
        return this.$store.getters["ui/GET_NOTIFICATION"];
      },
      set(val) {
        this.$store.commit("ui/NOTIFICATION", val);
      },
    },
    ...mapGetters({
      equipments: "equipment/equipments",
      totalFromServer: "equipment/totalFromServer",
      evidences: "documents/evidences",
      loading: "equipment/loading",
      requireAndMaxRules: "general/requireAndMaxRules",
      requireRules: "general/requireRules",
      lengthRules: "general/lengthRules",
      limitEnterKeyPress: "general/limitEnterKeyPress",
      languageStructure: "general/languageStructure",
      equalObjectsCompareRule: "general/equalObjectsCompareRule"
    }),
    headers: () => [
      { text: i18n.t("equipment.fields.name"), value: "equipment_name" },
      { text: i18n.t("equipment.fields.code"), value: "code" },
      {
        text: i18n.t("equipment.fields.description"),
        value: "description_text"
      },
      {
        text: i18n.t("equipment.fields.active"),
        value: "active",
        align: "center",
      },
      {
        text: i18n.t("common.fields.action"),
        value: "action",
        width: 120,
        sortable: false,
        align: "center",
      },
    ],
    formTitle() {
      return (
        (this.editedIndex === -1
          ? i18n.t("common.titles.new")
          : i18n.t("common.titles.edit")) +
        " " +
        i18n.t("equipment.titles.new_equipment")
      );
    },
  },

  watch: {
    options: {
      async handler() {
        this.optionsTable = this.options
        await this.searchText()
      },
      deep: true,
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      fetchListEquipment: "equipment/fetchListEquipment",
      saveEquipment: "equipment/saveEquipment",
      deleteEquipment: "equipment/deleteEquipment",
      clearEvidences: "documents/clearEvidences",
      exportCSV: "equipment/exportCSV"
    }),
    async searchAll() {
      let filters = {
        companyId: null,
        search: null,
        pageableDTO: {
          page: 0,
          size: 10,
          sortBy: 'id',
          direction: 'DESC',
        }
      }

      filters.companyId = this.profile.company_id

      await this.fetchListEquipment([filters, this.$toast])
    },
    async searchForText() {
      this.options.page = 1
      await this.searchText()
    },
    async searchText() {
      let filters = {
        companyId: null,
        search: null,
        pageableDTO: {
          page: null,
          size: null,
          sortBy: 'id',
          direction: 'DESC'
        }
      }

      filters.companyId = this.profile.company_id

      const direction =
          this.options.sortDesc.length === 0 || this.options.sortDesc[0]
              ? 'asc'
              : 'desc'
      const sort =
          this.options.sortBy.length === 0
              ? 'id'
              : _.filter(this.headers, { value: this.options.sortBy[0] })[0].value
      filters.pageableDTO = {
        page: this.options.page - 1,
        size: this.options.itemsPerPage,
        sortBy: sort,
        direction: direction,
      }

      if (this.search && this.search !== '')
        filters.search = this.search

      await this.fetchListEquipment([filters, this.$toast])
    },
    initialize() {
      this.profile = JSON.parse(localStorage.getItem("profile"));
      this.fetchListEquipment([this.defaultFilters, this.$toast]);
    },
    // Save Records
    save() {
      if (
        this.equalObjectsCompareRule(this.selectedItem, this.editedItem) &&
        this.editedItem.evidences.length === this.evidences.length
      ) {
        this.$toast.info(i18n.t("common.notification.nothingToModify"), {
          icon: 'mdi-information-slab-circle',
          queueable: true
        });
      } else {
        if (!this.editedItem.description_text)
          this.editedItem.description_text = "";

        let item = Object.assign({}, this.editedItem);
        // Translate
        item.language_key = LanguajeService.setKey3(
          item.equipment_name,
          item.language_key,
          !item.id ? this.languageStructure : null
        );
        item.description = LanguajeService.setKey3(
          item.description_text,
          item.description,
          !item.id ? this.languageStructure : null
        );
        // *****
        item.companyId = this.profile.company_id;

        this.saveEquipment([
          item,
          this.evidences,
          this.editedIndex,
          this.$toast,
        ]).finally(() => {
          this.fetchListEquipment([this.defaultFilters, this.$toast]);
        });
        this.close();
      }
    },
    checkBoxShow() {
      this.showCheckBox = !this.showCheckBox;
      if (!this.showCheckBox) this.selectedItems = [];
    },
    getByProperty(array, property) {
      return _.map(array, property);
    },
    async exportCSVEquipments() {
      this.loadingExport = true;
      const data = {
        language: LanguajeService.getLenguajeName(),
        equipments_ids: this.getByProperty(this.selectedItems, "id"),
      };
      await this.exportCSV([data, this.$toast]).finally(() => {
        this.loadingExport = false;
      });
    },
    showItem(item) {
      this.selectedItem = item;
      this.editedIndex = this.equipments.indexOf(item);
      this.editedItem = Object.assign({}, item);
      if (item.evidences && item.evidences.length > 0) {
        this.getEvidences(this.editedItem);
      }
      this.showItemDetailsDialog = true;
    },
    editItem(item) {
      this.selectedItem = item;
      this.editedIndex = this.equipments.indexOf(item);
      this.editedItem = Object.assign({}, item);
      if (item.evidences && item.evidences.length > 0) {
        this.getEvidences(this.editedItem);
      }
      this.dialog = true;
    },
    downloadEvidence(item) {
      this.loadingDownloadEvidences = true;
      EquipmentService.getEvidences(item)
        .then((response) => {
          const FileSaver = require("file-saver");
          const blob = new Blob([response.data], {
            type: "application/zip",
          });
          FileSaver.saveAs(
            blob,
            `equipment_${item.equipment_name}_evidences.zip`
          );

          this.$toast.success(
            i18n.t("common.notification.successDownloadEvidences"),
            {
              icon: 'mdi-check-circle',
              queueable: true
            }
          );
        })
        .catch((error) => {
          this.$toast.error(
            i18n.t("common.notification.errorDownloadEvidences"),
            {
              queueable: true
            }
          );
        })
        .finally(() => {
          this.loadingDownloadEvidences = false;
        });
    },
    getEvidences(editedItem) {
      const scope = this;
      scope.loadingEvidences = true;
      try {
        EquipmentService.getEvidences(editedItem)
          .then((response) => {
            const blob = new Blob([response.data], {
              type: "application/zip",
            });
            const jsZip = require("jszip");
            jsZip.loadAsync(blob).then((zip) => {
              Object.keys(zip.files).forEach((filename) => {
                zip.files[filename].async("blob").then((fileData) => {
                  // These are your file contents
                  let type = "";
                  const nameSpliter = filename.split(".");
                  if (nameSpliter[1] === "pdf" || nameSpliter[1] === "PDF") {
                    type = "application/pdf";
                  } else if (
                    nameSpliter[1] === "xlsx" ||
                    nameSpliter[1] === "XLSX"
                  ) {
                    type =
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                  } else if (
                    nameSpliter[1] === "xls" ||
                    nameSpliter[1] === "XLS"
                  ) {
                    type = "application/vnd.ms-excel";
                  } else {
                    type = "image/" + nameSpliter[1];
                  }
                  scope.$store.commit(
                    "documents/SET_EVIDENCES",
                    new File([fileData], filename, { type: type })
                  );
                });
              });
            });
          })
          .finally(() => {
            scope.loadingEvidences = false;
          });
      } catch (error) {
        scope.$toast.error(
          i18n.t("common.notification.loadEvidencesError"),
          {
            queueable: true
          }
        );
      }
    },
    deleteItem() {
      this.dialogClose();
      this.deleteEquipment([this.deletingItem.id, this.$toast]).finally(() => {
        this.fetchListEquipment([this.defaultFilters, this.$toast]);
      });
    },
    close() {
      if (this.dialog === true) this.dialog = false;
      if (this.showItemDetailsDialog === true)
        this.showItemDetailsDialog = false;
      this.$refs.form.reset();
      this.clearEvidences();
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    dialogShow(params) {
      this.title = params.title;
      this.dialogText = params.dialogText;
      this.showDialog = true;
    },
    dialogClose() {
      this.showDialog = false;
    },
    check(permissions) {
      return PermisionService.check(permissions);
    },
    deleteItemDialog(item) {
      this.deletingItem = item;
      this.dialogShow({
        title: i18n.t("common.titles.alert"),
        dialogText: i18n.t("common.notification.deleteItems"),
      });
    },
    replace(event){
      event.currentTarget.value =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replaceSearch(event){
      this.search =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replaceEquipmentName(event){
      this.editedItem.equipment_name =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replaceCode(event){
      this.editedItem.code =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replaceDescriptionText(event){
      this.editedItem.description_text =  event.currentTarget.value.toString().replaceAll('"','')
    },
    getButtons() {
      return [
        {
          type: 'button',
          text: this.$t("common.buttons.export"),
          icon: 'mdi-download-multiple',
          action: this.exportCSVEquipments,
          props: {
            loading: this.loadingExport,
            disabled: !(this.equipments && this.equipments.length > 0),
            vShow: this.check([{ domain: 'Equipments', permisions: ['Write'] }]),
          },
        },
        {
          type: 'button',
          text: this.$t("common.buttons.new"),
          icon: 'mdi-plus',
          action: () => { this.dialog = true; },
          props: {
            vShow: this.check([{ domain: 'Equipment', permisions: ['Write'] }]),
          },
        },
      ];
    },
  },
  destroyed() {
    this.notification = false;
  },
};
</script>

<style scoped></style>
